<template>
<div>
    <b-container fluid>
        <div class="row">
            <!-- <div class="col-md-2">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i>Artikel</h4>
                            <p>Liste aller Artikel</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-primary btn-sm" @click="resetArticlesByGroup" >
                                <i class="fas fa-sync"></i> Alle Artikel laden
                            </button>
                        </div>
                    </div>

                    <div class="card-body">
                        <Tree :value="treeView">
                            <span slot-scope="{node, index, path, tree}">
                                <i class="fas fa-folder-plus" @click="tree.toggleFold(node, path)" v-if="node.$folded"></i>
                                <i class="fas fa-folder-minus" @click="tree.toggleFold(node, path)" v-if="!node.$folded"></i>
                                <a class="ml-1" href="#" @click="loadArticlesByGroup(node.id)">{{node.name}}</a>
                            </span>
                        </Tree>
                    </div>
                </div>
            </div> -->


            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i>Artikel</h4>
                            <p>Liste aller Artikel</p>
                        </div>

                        <div class="clearfix card-tools">
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">  
                                            <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm" style="margin-top: 0;">
                                                    <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getAllArticles" :value="search" @input="$store.commit('articles/changeSearch', $event.target.value)" />
                                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="getAllArticles">
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                    <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="nav-item d-none d-md-block" >
                                                <!-- <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'abos.create'}" v-if="$auth.check('abos.create')"><i class="fas fa-plus-circle"></i> Neues Abo</router-link>              -->
                                                <router-link class="btn btn-secondary btn-sm mr-1" :to="{name: 'articles.penner'}" v-if="$auth.check('articles.create')"><i class="fas fa-bed"></i> Penner</router-link>
                                            </li>
                                            <li class="nav-item d-none d-md-block" >
                                                <button class="mr-1 btn btn-info btn-sm" @click="openManss()" v-if="$auth.check('articles.manss_import')"><i class="fas fa-file-export"></i> Manss Import</button>
                                            </li>
                                            <li class="nav-item d-none d-md-block" >
                                                <!-- <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'abos.create'}" v-if="$auth.check('abos.create')"><i class="fas fa-plus-circle"></i> Neues Abo</router-link>              -->
                                                <button class="mr-1 btn btn-warning btn-sm" @click="exportRSU()" v-if="$auth.check('articles.print')"><i class="fas fa-file-export"></i> RSU Export</button>
                                            </li>

                                            <li class="nav-item d-none d-md-block" >
                                                <!-- <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'abos.create'}" v-if="$auth.check('abos.create')"><i class="fas fa-plus-circle"></i> Neues Abo</router-link>              -->
                                                <button class="mr-1 btn btn-success btn-sm" href="#" @click="showLabelModal" v-if="$auth.check('articles.print')" :disabled="selectedArticles.length <= 0"><i class="fas fa-print"></i> Preisschild drucken</button>
                                            </li>

                                            <li class="nav-item d-none d-md-block">
                                                <!-- <button type="button" class="mr-1 btn btn-sm btn-success" @click="getResults" ><i class="fas fa-sync"></i></button>        -->
                                                <div class="mr-1 btn-group" role="group">
                                                    <button id="btnGroupDrop1" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="selectedArticles.length <= 0">
                                                    Stapelverarbeitung
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">        
                                                        <a class="dropdown-item" href="#" @click="doExport('articles', 'xlsx')" v-if="$auth.check('articles.export')">Export Artikel (Excel)</a>
                                                    </div>
                                                </div>
                                            </li>

                                            <li class="nav-item d-none d-md-block">
                                                <!-- <button type="button" class="btn btn-sm btn-secondary" data-widget="control-sidebar"><i class="fas fa-filter"></i></button> -->
                                                <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'articles.create'}" v-if="$auth.check('articles.create')"><i class="fas fa-plus-circle"></i></router-link>
                                            </li>

                                            <li class="nav-item d-none d-md-block">
                                                <button type="button" class="mr-1 btn btn-success btn-sm" @click="getAllArticles">
                                                    <i class="fas fa-sync"></i>
                                                </button>
                                            </li>

                                            <li class="nav-item d-none d-md-block">
                                                <button type="button" class="btn btn-sm btn-secondary" data-widget="control-sidebar"><i class="fas fa-filter"></i></button>
                                            </li>
                                            
                                        </ul>
                    
                                    </nav>
                                    
                                    <!-- <div class="mr-1 btn-group" role="group">
                                        <button id="btnGroupDrop1" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="selectedArticles.length <= 0">
                                        Stapelverarbeitung
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">        
                                            <a class="dropdown-item" href="#" @click="doExport('articles', 'xlsx')" v-if="$auth.check('articles.export')">Export Artikel (Excel)</a>
                                        </div>
                                    </div>
                                    <router-link class="btn btn-primary btn-sm" :to="{name: 'articles.create'}" v-if="$auth.check('articles.create')"><i class="fas fa-plus-circle"></i> Neuer Artikel</router-link>
                                    <button type="button" class="btn btn-sm btn-secondary" data-widget="control-sidebar"><i class="fas fa-filter"></i></button> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col>
                                <div class="table-responsive">
                                <table class="table table-hover table-sm">
                                    <thead>
                                        <th><input type="checkbox" v-model="selectAll" /></th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('name')">Artikelname</a>
                                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th><a href="#" @click.prevent="changeSort('producer')">Hersteller</a>
                                            <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th><a href="#" @click.prevent="changeSort('distributor')">Lieferant</a>
                                            <span v-if="this.params.sort_field == 'distributor' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'distributor' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('price')">Preis</a>
                                            <span v-if="this.params.sort_field == 'price' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'price' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            EK (Netto)
                                        </th>
                                        <!-- <th>
                                            Max Rabatt (%)
                                        </th> -->
                                        <th>Marge (%)</th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('tax')">Steuersatz</a>
                                            <span v-if="this.params.sort_field == 'tax' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'tax' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('active')">Aktiv</a>
                                            <span v-if="this.params.sort_field == 'active' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'active' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>Optionen</th>
                                        <th style="width: 140px;">Aktionen</th>
                                        
                                    </thead>
                                    <tbody>
                                        <tr v-for="(article,index) in articles.data" :key="article.id">
                                            <td><input type="checkbox" :value="article.id" v-model="selectedArticles"/></td>
                                            <td>{{ article.ordernumber }}</td>
                                            <td>{{ article.name }}</td>
                                            <td>{{ article.producer }}</td>
                                            <td>{{ article.distributor }}</td>
                                            <td>
                                                <div v-if="selectedRow[index] && selectedCell === 'price'" class="input-group">
                                                    <input type="number" class="form-control form-control-sm" v-model="articles.data[index].price" />
                                                    <div class="input-group-append">
                                                        <button class="btn btn-sm btn-primary" type="button" @click="changePrice('price', index)"><i class="fas fa-save"></i></button>
                                                        <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'price')"><i class="fas fa-times"></i></button>
                                                    </div>
                                                </div>
                                                <span @dblclick="handleEditCell(index, 'price')" v-if="(!selectedRow[index] || selectedCell !== 'price') && article.useFactor == 0">
                                                    {{ article.price | toCurrency}}<br>
                                                    <span v-if="article.pseudo_price >0" :class="{'text-danger': article.pseudo_price >0}">{{ article.pseudo_price | toCurrency }}</span>
                                                </span>
                                                <span v-if="(!selectedRow[index] || selectedCell !== 'price') && article.useFactor == 1">
                                                    {{ article.price | toCurrency}}<br>
                                                    <span class="text-primary">(Faktor)</span><br>
                                                    <span v-if="article.pseudo_price >0" :class="{'text-danger': article.pseudo_price >0}">{{ article.pseudo_price | toCurrency }}</span>
                                                </span>
                                            </td>
                                            <td>
                                                <div v-if="selectedRow[index] && selectedCell === 'purchase_price'" class="input-group">
                                                    <input type="number" class="form-control form-control-sm" v-model="articles.data[index].purchase_price" />
                                                    <div class="input-group-append">
                                                        <button class="btn btn-sm btn-primary" type="button" @click="changePrice('purchase_price', index)"><i class="fas fa-save"></i></button>
                                                        <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'purchase_price')"><i class="fas fa-times"></i></button>
                                                    </div>
                                                </div>
                                                <span @dblclick="handleEditCell(index, 'purchase_price')" v-else>{{ article.purchase_price | toCurrency }}<br>
                                                    <span v-if="article.useFactor == 1" class="text-primary">{{ article.factor }}</span>
                                                </span>
                                            </td>
                                            <td>
                                                <template v-if="article.useFactor == 0">
                                                    {{ (((parseFloat(article.price) / (parseFloat(article.tax) + 100) * 100)) - parseFloat(article.purchase_price)) / article.purchase_price | toPercent }} <br>
                                                </template>
                                                <template v-if="article.useFactor == 1">
                                                    {{ ((((parseFloat(article.purchase_price) * parseFloat(article.factor)) / (parseFloat(article.tax) + 100) * 100)) - parseFloat(article.purchase_price)) / article.purchase_price | toPercent }} <br>
                                                </template>
                                                <!-- {{ ((((parseFloat(article.price) / (parseFloat(article.tax) + 100)) * 100) - parseFloat(article.purchase_price)))  }} -->
                                                <!-- {{ (100 / (parseFloat(article.price) / (parseFloat(article.tax_rate) + 100) * 100) * (((parseFloat(article.price) / (parseFloat(article.tax_rate) + 100) * 100)) - parseFloat(article.purchase_price))) / 100 | toPercent  }} -->
                                            </td>
                                            <!-- <td>{{ ((((parseFloat(article.price) / (parseFloat(article.tax_rate) + 100) * 100)) - parseFloat(article.purchase_price))) /  ((parseFloat(article.price) / (parseFloat(article.tax_rate) + 100) * 100))| toPercent }}</td> -->
                                            <td>{{ article.tax }}</td>
                                            <td>
                                                <i v-if="article.active == 1" class="fas fa-check"></i>
                                                <i v-else class="fas fa-times"></i>
                                            </td>
                                            <td>
                                                <span class="ml-2 badge bg-primary" v-if="article.laststock == 1">AdS</span>
                                                <span class="ml-2 badge bg-danger" v-if="article.not_available == 1">z.Z.n.l.</span>
                                                <span class="ml-2 badge bg-success" v-if="article.mhd != null">MHD</span>
                                                <span class="ml-2 badge bg-warning" v-if="article.seasonal == 1">Saison </span>
                                            </td>
                                            <td>
                                                <!-- <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'products.details', params: {id: props.row.product.id}}" v-if="$auth.check('products.show') && props.row.product != null"><i class="fas fa-box"></i></router-link> -->
                                                <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'articles.show', params: {id: article.id}}" v-if="$auth.check('articles.show') && !$auth.check('articles.edit')"><i class="fas fa-fw fa-eye"></i></router-link>
                                                <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'articles.details', params: {id: article.id}}" v-if="$auth.check('articles.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                                <b-button size="xs" @click="deleteArticle(article.id)" variant="danger" v-if="$auth.check('articles.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>

                                
                            </b-col>
                        </b-row>
                    </div>
                    <div class="clearfix align-middle card-footer">
                        <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
                        
                        <select class="float-right form-control-sm" v-model="params.per_page">
                            <option value="25">25 Artikel</option>
                            <option value="50">50 Artikel</option>
                            <option value="75">75 Artikel</option>
                            <option value="100">100 Artikel</option>
                            <option value="125">125 Artikel</option>
                            <option value="150">150 Artikel</option>
                        </select>
                        <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
                    </div>
                </div>
            </div>
        </div>


        <b-modal id="manss_modal" title="Manss" ok-only ok-variant="danger">
            <div class="row">
                <div class="col-md-9">
                    <div class="form-group">
                        <b-form-file
                            v-model="import_file.file"
                            :state="Boolean(import_file.file)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </div>
                </div>
                <div class="col-md-3">
                    <button class="btn btn-success btn-sm" @click="importManssFile">Importieren</button>
                </div>
            </div>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Benutzer</th>
                        <th>Importiert</th>
                        <th>Fehlende Artikel</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in imports" :key="item.id">
                        <td>{{ item.created_at | formatDateTime }}</td>
                        <td>{{ item.user.first_name }} {{ item.user.last_name }}</td>
                        <td>{{ item.import_count - item.missing_count }} von {{ item.import_count }}</td>
                        <td>
                            <button v-if="item.not_imported != null" class="btn btn-warning btn-sm" @click="show_missing_manss(item.not_imported)"><i class="fas fa-exclamation-triangle"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-modal>
        <b-modal id="labelModal" title="Artikeletiketten drucken" ok-only ok-variant="danger" ok-title="Drucken" size="l" @ok="doLabels" @hidden="resetSelection">

            <div class="form-group">
                <label>Modus</label>
                <select class="form-control form-control-sm" v-model="label.mode" :disabled="usePrintnode == 'false'">
                    <option value="print">Direkt drucken</option>
                    <option value="pdf">Download als PDF</option>
                </select>
            </div>

            <div class="form-group" v-if="label.mode == 'print'">
                <label>Filiale</label>
                <select class="form-control form-control-sm" v-model="label.printer">
                    <option v-for="printer in printers" :key="printer.id" :value="printer.id">{{ printer.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label>Anzahl pro Etikett</label>
                <input type="number" class="form-control form-control-sm" v-model="label.qty" />
            </div>

            <!-- <template #modal-footer="{ ok }">
                <b-button size="sm" variant="primary" @click="ok()">
                    OK
                </b-button>
            </template> -->
        </b-modal>
        <b-modal id="manssVariationModal" title="Artikel verknüpfen" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true" ok-variant="danger" ok-title="Speichern" size="lg" @ok="handleManssStep2">
            <table class="table table-striped">
                <thead>
                    <th>
                        Artikelnummer
                    </th>
                    <th>
                        Artikel
                    </th>
                    <th>
                        Einkaufspreis (Netto)
                    </th>
                    <th>
                        Herkunftsland
                    </th>
                    <th>
                        Manss
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in manssResponse" :key="index">
                        <td>{{ item.detail.ordernumber }}</td>
                        <td>{{ item.detail.full_name }}</td>
                        <td>
                            {{ Number(item.detail.purchase_price) | toCurrency }}
                        </td>
                        <td>
                            <template v-if="item.detail.article.nutrition != null">
                                <template v-if="item.detail.article.nutrition.country">
                                    {{ item.detail.article.nutrition.country.iso2 }}
                                </template>
                            </template>
                        </td>
                        <td>
                            <select class="form-control" v-model="manssResponse[index].selectedManss">
                                <option :value="null">-- Keine Zuweisung --</option>
                                <option v-for="(variation) in item.items" :key="variation.artikelnummer" :value="variation.artikelnummer">{{ variation.artikelnummer }} | {{ variation.artikelbezeichnung }} - {{ variation.preis | toCurrency }} Netto - {{ variation.herkunftsland }}</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-modal>
    </b-container>
    <aside class="control-sidebar control-sidebar-light overflow-auto">
        <div class="p-3">
            <h3>Filteroptionen</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Hersteller:</label>
                        <select v-model="params.producer_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Hersteller --</option>
                            <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.number }} - {{ producer.name  }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Lieferant:</label>
                        <select v-model="params.distributor_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Lieferanten --</option>
                            <option v-for="distributor in distributors" :key="distributor.id" :value="distributor.id">{{ distributor.number }} - {{ distributor.name  }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Firma:</label>
                        <select v-model="params.company_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Firma --</option>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name  }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Steuersatz:</label>
                        <select v-model="params.tax_id" class="form-control form-control-sm">
                            <option :value="''">-- Steuer --</option>
                            <option v-for="tax in taxes" :key="tax.id" :value="tax.id">{{ tax.description }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Aktiv:</label>
                        <select v-model="params.is_active" class="form-control form-control-sm">
                            <option :value="''">-- Aktiv --</option>
                            <option value="0">Inaktiv</option>
                            <option value="1">Aktiv</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Optionen:</label>
                        <select v-model="params.options" class="form-control form-control-sm">
                            <option :value="''">--  Optionen --</option>
                            <option value="ads">AdS</option>
                            <option value="mhd">MHD</option>
                            <option value="na">Nicht lieferbar</option>
                            <option value="season">Saison</option>
                            <option value="discount">Rabatt</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Zeige Artikel mit fehlenden Bildern:</label>
                        <select v-model="params.image" class="form-control form-control-sm">
                            <option value="0">Inaktiv</option>
                            <option value="1">Aktiv</option>
                        </select>
                    </div>
                </div>
            </div>

            <hr>


            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Kühlzuschlag:</label>
                        <select v-model="params.cool" class="form-control form-control-sm">
                            <option :value="null">-- Optionen --</option>
                            <option value="0">Inaktiv</option>
                            <option value="1">Aktiv</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Sperzuschlag:</label>
                        <select v-model="params.cargo" class="form-control form-control-sm">
                            <option :value="null">-- Optionen --</option>
                            <option value="0">Inaktiv</option>
                            <option value="1">Aktiv</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Bruchzuschlag:</label>
                        <select v-model="params.fragile" class="form-control form-control-sm">
                            <option :value="null">-- Optionen --</option>
                            <option value="0">Inaktiv</option>
                            <option value="1">Aktiv</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <div class="form-group">
                        <button type="button" class="btn btn-sm btn-secondary" @click="resetFilter">Filter zurücksetzen</button>
                    </div>
                </div>
            </div>
        </div>  
    </aside>
</div>
</template>

<script>
import 'he-tree-vue/dist/he-tree-vue.css'
// import {Tree, Fold} from 'he-tree-vue'
import {mapState} from 'vuex';

export default {
    // components: {
    //     Tree: Tree.mixPlugins([Fold])
    // },
    
    data() {
		return {
            usePrintnode: process.env.VUE_APP_USE_PRINTNODE,
            treeView: [],
			// Our data object that holds the Laravel paginator data
			articles: {},
            companies: [],
            producers: {},
            distributors: {},
            taxes: {},
            selectedArticles: [],
            printers: [],
            selectedRow: {},
            selectedCell: null,
            label:{
                mode: 'print',
                printer: '',
                qty: 1,
            },
            imports: [],
            import_file: new window.Form({
                file: null,
                _method: "POST",
            }),
            manssResponse: [],
            last_import_id: null,
            // params: {
            //     sort_field: 'created_at',
            //     sort_direction: 'desc',
            //     producer_id: '',
            //     tax_id: '',
            //     is_active: '',
            //     options: '',
            //     per_page: 25,
            //     group_id: '',
            //     //page: '',
            // },
            // search: '',
            // page: '',
		}
	},

    watch: {
        params: {
            handler () {
                this.getAllArticles(1);
            },
            deep: true
        },
    },

    computed: {
        selectAll: {
            get: function () {
                return this.articles.data ? this.selectedArticles.length == this.articles.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.articles.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedArticles = selected;
            }
        },
        ...mapState('articles', ['params', 'search', 'page'])
    },




	methods: {
        handleManssStep2(evt) {
            evt.preventDefault();
            console.log("sdf");
            
            let items = [];
            this.manssResponse.forEach(item => {
                items.push({
                    article_detail_id: item.detail.id,
                    han: item.selectedManss,
                })
            })
            this.axios
                .get('/articles/importManss/update', {
                    responseType: 'arraybuffer',
                    params: {
                        last_import_id: this.last_import_id,
                        items: items,
                    }
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",  'statistik.xlsx');
                    document.body.appendChild(link);

                    link.click();

                    this.$bvModal.hide("manssVariationModal");
                    this.$swal({
                        icon: "success",
                        title: "Erfolgreich importiert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });

                    this.getAllArticles();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    });
                })
        },
        getImports() {
            this.axios
                .get('/imports')
                .then((response) => {
                    this.imports = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    });
                })
        },

        downloadManssFile(response){
            var newBlob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'ManssExport.xlsx'
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)
        },

        openManss() {
            this.$bvModal.show('manss_modal');
        },
        show_missing_manss(item) {
            this.$bvModal.hide('manss_modal');

            this.$swal({
                icon: 'warning',
                title: 'Fehlende Artikel:',
                html: item,
                width: '850px'
            })
        },
        importManssFile(evt) {
            evt.preventDefault();
            this.import_file
                .post('/articles/importManss', {
                    file: this.import_file
                })
                .then((response) => {
                    this.$bvModal.show('manssVariationModal')
                    this.manssResponse = response.data.variations;
                    this.last_import_id = response.data.last_import_id
                    // this.getImports();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                });
        },
        resetSelection(){
            this.selectedArticles = [];
        },

        resetFilter(){
            this.params.producer_id = '';
            this.params.tax_id = '';
            this.params.is_active = '';
            this.params.per_page = 25;
            this.params.group_id = '';
            this.params.company_id = ''; 
            this.params.options = '';
            this.params.distributor_id = '';
            this.params.image = 0;

            this.params.cool = null;
            this.params.fragile = null;
            this.params.cargo = null;
        },

        resetSearch(){
            this.$store.commit('articles/changeSearch', '');
            this.getAllArticles();
        },

        doLabels(bvModalEvt){
            bvModalEvt.preventDefault();

            if(this.label.mode == 'pdf')
            {
                //Die ganzen Etiketten bitte als PDF
                this.labelPdf();
            }

            if(this.label.mode == 'print')
            {
                this.labelPrint();
            }

        },

        labelPrint(){
            this.axios
            .post('/articles/label/print', {
                qty: this.label.qty,
                store_id: this.label.printer,
                articles: this.selectedArticles
            })
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Druck erfolgreich!",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                this.label.qty = 1;
                // this.$bvModal.hide('labelModal');
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },


        labelPdf(){
            this.axios
            .post('/articles/label/download', {
                qty: this.label.qty,
                articles: this.selectedArticles
            },
            {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                this.$swal({
                    icon: "success",
                    title: "Download erfolgreich!",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                this.label.qty = 1;
                // this.$bvModal.hide('labelModal');
                this.downloadLabel(response)
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },


        showLabelModal(){
            if(this.usePrintnode == 'false')
            {
                this.label.mode = 'pdf';
            }
            this.$bvModal.show('labelModal');
        },

        changePrice(field, index){
        
            var url = '';
            var price = 0;
            var vk = null;
            if(field == 'price')
            {   
                url = '/articles/update/price';
                price = parseFloat(this.articles.data[index].price);
            }
            else
            {
                url = '/articles/update/purchase_price';
                price = parseFloat(this.articles.data[index].purchase_price);
                if(this.articles.data[index].useFactor == 1){
                    vk = Number(parseFloat((Number(parseFloat(price * this.articles.data[index].factor).toFixed(2)) / (100 + parseFloat(this.articles.data[index].tax_rate))) * 100).toFixed(2));
                    // console.log(Number(parseFloat(price * this.articles.data[index].factor).toFixed(2)) / (100 + parseFloat(this.articles.data[index].tax_rate)))
                }
            }

            // console.log(url);
            // console.log(price);

            this.axios
            .post(url, {
                article_id: this.articles.data[index].id,
                price: price,
                vk: vk,
            })
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Artikel gespeichert!",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                this.handleEditCell(index, field);
                this.getAllArticles();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        handleEditCell(index, name) {
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },
        
        deleteArticle(id)
        {   
            this.$swal({
                    title: "Möchtest du den Artikel wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/articles/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Artikel wurde erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                //this.selectedDistributors = [];
                                this.getAllArticles();
                            })
                            .catch((error) => {
                                var message = 'Da ist etwas schief gelaufen'; 
                                if('error' in error.response.data)
                                {
                                    message = error.response.data.error; 
                                }
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: message, 
                                });
                            });
                    }
                });
        },


        doExport(type, format){

            //Type=articles,inventur,locafox
            //Format=xlsx,csv,pdf

            this.axios
                .post("/articles/export",
                {
                    type: type,
                    format: format,
                    articles: this.selectedArticles.length > 0 ? this.selectedArticles : null,
                },
                {
                    responseType: 'arraybuffer'
                })
                .then((response) => {
                    this.downloadFile(response, format)
                    this.selectedArticles = [];
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
        
        downloadLabel(response){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Etiketten.pdf'
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)
        },


        downloadFile(response, format){
            var mimetype;
            var extension;

            if(format == 'xlsx')
            {
                mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                extension = 'xlsx';
            }
            else if (format == 'csv')
            {
                mimetype = 'text/csv';
                extension = 'csv';
            }
            else if (format == 'pdf')
            {
                mimetype = 'application/pdf';
                extension = 'pdf';
            }
            else
            {
                mimetype = 'text/plain';
                extension = 'txt';
            }


            var newBlob = new Blob([response.data], {type: mimetype})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Artikelexport.' + extension
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)

        },

        resetArticlesByGroup() {
            this.params.group_id = '';
        },

        loadArticlesByGroup(id) {
            this.params.group_id = id;
        },

        loadGroups() {
            this.$Progress.start();
            this.axios
                .get("/groups/tree")
                .then((response) => {
                    this.treeView = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        getCompanies(){
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getAllArticles(page) {
            
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/articles", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                    this.$store.commit('articles/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getDistributors() {
            this.axios
                .get("/distributors", {
                    params: {
                        per_page: 1000,
                    }                   
                })
                .then((response) => {
                    this.distributors = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers", {
                    params: {
                        per_page: 1000,
                    }
                })
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getTaxes() {
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getPrinters() {
            this.$Progress.start();
            this.axios
                .get("/articles/label/printers")
                .then((response) => {
                    //console.log(response.data);
                    this.printers = response.data;
                    
                    if(Object.keys(response.data).length > 0)
                    {
                        this.label.printer = response.data[0].id;
                    }
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        exportRSU() {
            this.$Progress.start();
            this.axios
                .get("articles/rsu/export/csv",{
                    responseType: 'blob',
                })
                .then((response) => {
                    this.$Progress.finish();
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", 'RSU.xlsx');
                    document.body.appendChild(link);

                    link.click();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Artikel konnten nicht exportiert werden'
                    })
                });
        }
	},

    mounted() {
		this.getAllArticles();
        this.loadGroups();
        this.getProducers();
        this.getDistributors();
        this.getTaxes();
        this.getPrinters();
        this.getCompanies();
        this.getImports();
	},

}
</script>
<style>
.swal-wide {
    width:850px !important;
}
</style>